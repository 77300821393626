require('@popperjs/core');
require('bootstrap');

var Turbolinks = require("turbolinks");
Turbolinks.start();

import Swiper from 'swiper';
import 'swiper/css';

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
